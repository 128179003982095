import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

if (window.location.protocol === 'http:' && window.location.host.indexOf('local') < 0) {
    const httpsUrl = window.location.href.replace(/^http:/, 'https:');
    window.location.assign(httpsUrl);
}

window.ucFirst = string => string ? `${string.charAt(0).toUpperCase()}${string.slice(1)}` : '';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
