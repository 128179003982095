import * as React from 'react';
import { Link } from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    InputBase,
    Button,
    Stack,
    useMediaQuery,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Box,
} from '@mui/material';
import {Close, Menu, Search} from '@mui/icons-material';
import logo from '../../assets/images/newslineai-logo.png';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#131313',
        },
        secondary: {
            main: '#444444',
        },
    },
    typography: {
        fontFamily: 'Roboto, sans-serif',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
    },
});

const menuItems = [
    {
        label: 'All',
        path: '/browse',
    },
    {
        label: 'News',
        path: '/browse/news',
    },
    {
        label: 'Sports',
        path: '/browse/sports',
    },
    {
        label: 'Entertainment',
        path: '/browse/entertainment',
    },
    {
        label: 'Business',
        path: '/browse/business',
    },
];

export default function Header() {
    const isMobile = useMediaQuery(() => theme.breakpoints.down('md'));
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState('');

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: '#ebeeef', boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)' }} elevation={1}>
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Stack direction="row" alignItems="center">
                    {isMobile ? (
                        <>
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2, color: theme.palette.primary.main }}
                                onClick={toggleDrawer}
                            >
                                <Menu />
                            </IconButton>
                            <Link to="/" className={'text-center'}>
                                <img src={logo} alt="Logo" style={{ height: '20px' }} />
                            </Link>
                        </>
                    ) : (
                        <Link to="/">
                            <img src={logo} alt="Logo" style={{ height: '36px', paddingRight: '100px' }} />
                        </Link>
                    )}
                    {!isMobile && (
                        <Stack direction="row" spacing={2} alignItems="center">
                            {menuItems.map((menuItem) => (
                                <Button
                                    key={menuItem.path}
                                    component={Link}
                                    to={menuItem.path}
                                    color="inherit"
                                    sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}
                                >
                                    {menuItem.label}
                                </Button>
                            ))}
                        </Stack>
                    )}
                </Stack>
                {isMobile && (
                    <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2, color: theme.palette.primary.main }}
                            onClick={toggleDrawer}
                            className={'pull-right'}
                        >
                            <Close />
                        </IconButton>
                        <List sx={{ width: '200px' }}>
                            {menuItems.map((menuItem) => (
                                <ListItem
                                    button
                                    key={menuItem.path}
                                    component={Link}
                                    to={menuItem.path}
                                    onClick={toggleDrawer}
                                >
                                    <ListItemText primary={menuItem.label} />
                                </ListItem>
                            ))}
                        </List>
                    </Drawer>
                )}
            </Toolbar>
        </AppBar>
    );
}