import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Browse from "./components/app/Browse";

function App() {
  return (
      <Router>
        <div className="flex flex-col min-h-screen">
          <Header />
          <main className="flex-grow">
            <div className="my-6">
              <Routes>
                  <Route path="/" element={<Browse />} />
                  <Route path="/browse/:category" element={<Browse />} />
                  <Route path="/browse" element={<Browse />} />
              </Routes>
            </div>
          </main>
          <Footer />
        </div>
      </Router>
  );
}

export default App;
