import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

let API_ENDPOINT = 'https://newsline-api.c5office.com';

if ( window.location.hostname.indexOf('local') > -1 ) API_ENDPOINT = `http://localhost:7194`;

function Browse() {
    let { category } = useParams();
    const [articles, setArticles] = useState([]);
    const [categoryName, setCategoryName] = useState('');
    const [page, setPage] = useState(1);
    const [noResults, setNoResults] = useState(false);
    const [articleView, setArticleView] = useState({});

    useEffect(() => {
        fetchArticles(1);
    }, [category]);

    const fetchArticles = (pageNumber) => {
        if ( !category ) category = 'all';
        setPage(pageNumber);
        setCategoryName(window.ucFirst(category));
        axios.get(`${API_ENDPOINT}/api/feed/${pageNumber}?category=${category}`)
            .then(response => {
                setArticles(response.data.articles);
                if ( response.data.articles.length < 1) setNoResults(true);
            })
            .catch(error => {
                console.error('Error fetching articles:', error);
            });
    }

    const formatDate = (date) => {
        const publishDate = new Date(date);
        const now = new Date();
        const diffInMilliseconds = now - publishDate;
        const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);
        const diffInMonths = Math.floor(diffInDays / 30);

        if (diffInMonths > 0) {
            return publishDate.toLocaleString();
        } else if (diffInDays > 0) {
            if (diffInDays === 1) {
                return 'Yesterday';
            } else {
                return `${diffInDays} days ago`;
            }
        } else if (diffInHours > 0) {
            return `${diffInHours} hours ago`;
        } else if (diffInMinutes > 0) {
            return `${diffInMinutes} minutes ago`;
        } else {
            return 'Just now';
        }
    };

    const paging = <div className={"mb-5 mt-5 text-xs"}>
        {page > 1 && <><button onClick={() => {fetchArticles(page - 1)}}>&lArr;&nbsp;Previous</button> &nbsp;&nbsp;[<strong>page {page}</strong>]&nbsp;&nbsp; </>}<button onClick={() => {fetchArticles(page + 1)}}>Next&nbsp;&rArr;</button>
    </div>;

    return (
        <>
            <div className="flex justify-center items-center">
                <div className="w-4/5 md:w-2/5">
                    <h1 className="text-3xl font-bold mb-4">Browsing: {categoryName}</h1>
                    {paging}
                    {articles.length < 1 && <><div className={"py-5 text-xl text-red-900"}>Sorry, we found no more articles that matched your search.</div></>}
                    {articles.map((article) => (
                        <div
                            key={article.id}
                            className="mb-10"
                        >
                            <h2 className="text-xl font-bold mb-2 text-gray-900">{article.title}</h2>
                            <p className="text-gray-400 text-sm mb-2" title={article.publishedAt}>
                                {formatDate(article.publishedAt)} in {window.ucFirst(article.category)}
                            </p>
                            {!articleView[article._id] &&
                                <>
                                    <p className={'mb-5 text-xs'}><span className={'clickable'} onClick={() => setArticleView({...articleView, [article._id]: true})}>SHOW SUMMARY</span></p>
                                </>
                            }

                            {articleView[article._id] &&
                                <>
                                    <div className="bg-white rounded-md shadow-md p-6 mb-4 text-sm">
                                        <p className={'mb-5 text-xs'}><span className={'clickable'} onClick={() => setArticleView({...articleView, [article._id]: false})}>HIDE SUMMARY</span></p>
                                        <ul className="list-disc list-inside">
                                            {article.points.map((point, index) => (
                                                <li key={index} className="mb-1 text-gray-600">
                                                    {point}
                                                </li>
                                            ))}
                                        </ul>
                                        <p className={"text-xs mt-5"}>
                                            {article.sources?.length > 0 && <><a href={article.sources[0]}
                                                                                 target={"_blank"}>Source</a></>}
                                        </p>
                                    </div>
                                </>
                            }
                        </div>
                    ))}
                    {articles.length > 10 && <>{paging}</>}
                </div>
            </div>
        </>
    );
}

export default Browse;
