import * as React from 'react';

export default function Footer() {

    return (
        <>
            <div className={"text-center text-gray-400 text-sm"}>
                NewsLine is just a smart aggregator. We're not journalists. We therefore DO NOT own any rights to the content presented on our platform.
                <br /><br />
            </div>
        </>
    );
}